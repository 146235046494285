import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Spin } from 'antd';
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";

const IndexPage = lazy(() => import('./pages/IndexPage'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Suspense fallback={
      <div style={{ padding: 40 }}><Spin /></div>
    }>
      <Routes>
        <Route path='/' element={<IndexPage />} >
        </Route>
      </Routes>
    </Suspense>
  </Router>
);
